import * as React from "react";
import { Box, CircularProgress, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import { changeTimezone } from "../../util/dateFunctions";

const GambitOverview = ({ accessToken, fullPlantReadings, thirtyMinPlantReadings, tzName, plant }) => {
  const { windowHeight, windowWidth } = useWindowDimensions();
  const [sampleRate, setSampleRate] = React.useState(30);
  const [trimmedPlantReadings, setTrimmedPlantReadings] = React.useState(null);
  const [timestamps, setTimestamps] = React.useState(null);
  const [realPowerMws, setRealPowerMws] = React.useState(null);
  const [dischargeEnergyOnlineMwhs, setDischargeEnergyOnlineMwhs] =
    React.useState(null);
  const [dischargeEnergyTotalMwhs, setDischargeEnergyTotalMwhs] =
    React.useState(null);
  const [megaPacksOnlineCounts, setMegaPacksOnlineCounts] =
    React.useState(null);

  React.useEffect(() => {
    let trimmed = null;
    if (fullPlantReadings) {
      trimmed = fullPlantReadings.filter(
        (e, i) => i % sampleRate === sampleRate - 1
      );
    } else if (thirtyMinPlantReadings && sampleRate == 30) {
      trimmed = thirtyMinPlantReadings;
    } else {
      trimmed = []
    }
    setTrimmedPlantReadings(trimmed);
    if (trimmed) {
      setTimestamps(
        trimmed.map((reading) =>
          changeTimezone(new Date(reading.TimestampAsLong), tzName)
        )
      );
      if (Number(plant.PlantId)<=12) {
        setRealPowerMws(trimmed.map((reading) => reading.RealPowerMw));
      } else if (Number(plant.PlantId)>12) {
        setRealPowerMws(trimmed.map((reading) => reading.RealPowerMw*-1));
      };    
      setDischargeEnergyOnlineMwhs(
        trimmed.map((reading) => reading.DischargeEnergyOnlineMwh)
      );
      if (Number(plant.PlantId)<=8) {
        setDischargeEnergyTotalMwhs(
          trimmed.map((reading) => reading.DischargeEnergyTotalMwh)
        );
      } else if (Number(plant.PlantId)>8 && Number(plant.PlantId)<=12) {
        setDischargeEnergyTotalMwhs(
          trimmed.map((reading) => (reading.StringsOnlineCount)*0.344)
        );
      } else if (Number(plant.PlantId)>12) {
        setDischargeEnergyTotalMwhs(
          trimmed.map((reading) => reading.DischargeEnergyTotalMwh)
        );
      };
      setMegaPacksOnlineCounts(
        trimmed.map((reading) => reading.ContainersOnlineCount)
      );
    }
  }, [fullPlantReadings, thirtyMinPlantReadings, sampleRate]);

  const handleSampleRateChange = (event, newSampleRate) => {
    setSampleRate(parseInt(newSampleRate));
  };

  const fullDataIsAvailable = () => {
    const ret = fullPlantReadings != null;
    return ret;
  }

  const thirtyMinDataIsAvailable = () => {
    const ret = thirtyMinPlantReadings != null;
    return ret;
  }

  const someDataIsAvailable = () => {
    const ret = fullDataIsAvailable() || thirtyMinDataIsAvailable();
    return ret;
  }

  return (
    !someDataIsAvailable()
    ?
    <CircularProgress/>
    :
    <Box>
      <Plot
        data={[
          {
            x: timestamps,
            y: realPowerMws,
            name: "BESS",
            type: "scatter",
            mode: "lines",
            line: {
              color: "rgb(45,156,138)",
              width: 2,
            },
            yaxis: "y2",
          },
          {
            x: timestamps,
            y: dischargeEnergyOnlineMwhs,
            name: "Available Energy",
            type: "scatter",
            mode: "lines",
            line: {
              color: "rgb(245,129,5)",
              width: 1,
            },
            fill: "tozeroy",
            fillcolor: "rgba(245,129,5,0.4)",
            yaxis: "y1",
          },
          {
            x: timestamps,
            y: dischargeEnergyTotalMwhs,
            name: "Energy Capacity",
            type: "scatter",
            mode: "lines",
            line: {
              color: "rgb(245,81,81)",
              width: 2,
            },
            yaxis: "y1",
          },
          {
            x: timestamps,
            y: megaPacksOnlineCounts,
            name: "Batteries Online",
            type: "scatter",
            mode: "lines",
            line: {
              color: "rgb(142,62,222)",
              width: 2,
            },
            yaxis: "y2",
          },
        ]}
        layout={{
          template: "simple_white",
          uirevision: 'true',
          width: windowWidth - 165,
          height: 600,
          xaxis: { domain: [0.08, 0.93], tickfont: { size: 14 } },
          yaxis2: {
            title: "BESS Charge/Discharge [MW]",
            titlefont: { color: "#2d9c89", size: 22 },
            tickfont: { color: "#2d9c89", size: 18 },
            anchor: "x",
            overlaying: "y",
            side: "right",
          },
          yaxis: {
            title: "BESS Energy [MWh]",
            titlefont: { color: "#f55151", size: 22 },
            tickfont: { color: "#f55151", size: 18 },
          },
        }}
      />
      Sample Rate:
      <ToggleButtonGroup
        color="primary"
        value={`${sampleRate}`}
        exclusive
        onChange={handleSampleRateChange}
        aria-label="Lat"
      >
        <ToggleButton value="1" disabled={!fullDataIsAvailable()} style={{ width: '60px' }}> 
          { fullDataIsAvailable() ? <Typography style={{ fontSize: '10px' }}>1 min</Typography> : <CircularProgress size={16}/>}
        </ToggleButton> 
        <ToggleButton value="5" disabled={!fullDataIsAvailable()} style={{ width: '60px' }}>
          { fullDataIsAvailable() ? <Typography style={{ fontSize: '10px' }}>5 min</Typography> : <CircularProgress size={16}/>}
        </ToggleButton>
        <ToggleButton value="15" disabled={!fullDataIsAvailable()} style={{ width: '60px' }}>
          { fullDataIsAvailable() ? <Typography style={{ fontSize: '10px' }}>5 min</Typography> : <CircularProgress size={16}/>}
        </ToggleButton>
        <ToggleButton value="30" style={{ width: '60px' }}> 
          <Typography style={{ fontSize: '10px' }}>30 min</Typography>
        </ToggleButton>{" "}
        <ToggleButton value="60" disabled={!fullDataIsAvailable()} style={{ width: '60px' }}>
          { fullDataIsAvailable() ? <Typography style={{ fontSize: '10px' }}>1 hr</Typography> : <CircularProgress size={16}/>}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default GambitOverview;
