import * as React from "react";
import { Box, Grid, Card, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import { groupBy, meanBy, minBy, maxBy, sumBy, first, mean } from 'lodash';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { changeTimezone, monthAndYearToShortString } from "../../util/dateFunctions";
import { linearRegression } from "../../util/calcs";
import AnnotationDialog from "../../common/AnnotationDialog";

const GambitAvail = ({ accessToken, kpiMetricsFullYear, plant, plantId, month, year, plantAnnotations, appConfig, refreshPlantAnnotations }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [monthYearValues, setMonthYearValues] = React.useState(null);
    const [megapacksOnlineCts, setMegapacksOnlineCts] = React.useState(null);
    const [availabilityPcts, setAvailabilityPcts] = React.useState(null);
    
    React.useEffect(() => {
        setMonthYearValues(
            kpiMetricsFullYear.map(entry => {
            return monthAndYearToShortString(entry.month, entry.year);
        })
        );
        setMegapacksOnlineCts(
            kpiMetricsFullYear.map((entry) => entry.MegapackAvg)
        );
        setAvailabilityPcts(
            kpiMetricsFullYear.map((entry) => entry.Availability)
        );
    }, [kpiMetricsFullYear]);

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "AVAILOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }

    return (
        <Grid container spacing={0}>
        <Box>
        <Plot
            data={[
                {
                x: monthYearValues,
                y: availabilityPcts,
                name: "Availability",
                type: "bar",
                marker: {
                    color: "rgba(38,167,197,1)"
                },
                yaxis: "y1",
                },
                {
                x: monthYearValues,
                y: megapacksOnlineCts,
                name: "Megapacks Online",
                type: "scatter",
                mode: "lines",
                line: {
                    color: "rgba(245,81,81,1)",
                    width: 5
                },
                yaxis: "y2",
                },
            ]}
            layout={{
                xaxis: {

                },
                yaxis: {
                    title: {
                        text: 'Availability %'
                    }
                },
                yaxis2: {
                    title: {
                        text: 'Batteries Online'
                    },
                    anchor: "x",
                    overlaying: "y",
                    side: "right",
                    autorange: true 
                },
                annotations: [
                    {
                        x: 1.10,
                        xanchor: 'right',
                        y: 1.0,
                        yanchor: 'bottom',
                        xref: 'paper',
                        yref: 'paper',
                        text: 'Average Availability (Year): <b>'+Math.round(mean(availabilityPcts)*10)/10+'%</b><br>Average Availability (Life): <b>99.7%</b> <br>Guaranteed Availability: <b>97%</b>',
                        showarrow: false,
                        font: {
                            size: 20,
                            color: 'black'
                          },
                        align: 'left',
                        bordercolor: 'black',
                        borderwidth: 3,
                        borderpad: 4, 
                        bgcolor: 'white',
                        opacity: 0.9
                    }
                ],
                template: "simple_white",
                uirevision: 'true',
                title: {
                    text: 'Availability',
                    font: {
                        size: 20
                    }
                },
                width: windowWidth - 165,
                height: windowHeight - 350,
            }}
        />
        </Box>
        <Grid>
        <Card elevation={0}>
                <CardContent>
                    <Typography sx={{ fontSize: 28, fontWeight: 'bold', textDecoration: 'underline' }}>
                        Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon/></IconButton>) : null}
                    </Typography>
                            {
                                getObservationsAndRecommendations().map((annotation) => {
                                    return (
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: 16 }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: 22 }}>
                                                {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                                {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                                <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                                {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }
                                )
                            }
                        </CardContent>
                    </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'AVAILOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
        </Grid>
    </Grid>
    );
};

export default GambitAvail;